import './App.css';
import {useRef, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Home from './Home';
import Analysis from './Analysis';
import Data from './Data';
import Login from './Login';
import Register from './Register';
import Profile from './Profile';

function App() {

return (
<Router>
<div>
  <Routes>
    <Route path="/home" element={<Analysis />} />
    <Route path="/analysis" element={<Analysis />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/data" element={<Data />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/" element={<Analysis />} />
  </Routes>
</div>
</Router>
);

}

export default App;