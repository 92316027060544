import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement} from 'chart.js';
import './Data.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

function Data() {
  const navigate = useNavigate();
  
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('tab1');

  // Handlers to switch between tabs
  const handleTab1 = () => {
    setActiveTab('tab1');
  };

  const handleTab2 = () => {
    setActiveTab('tab2');
  };

  const pieData = {
    labels: ['Anger', 'Disgust', 'Sad', 'Contempt', 'Fear', 'Surprise', 'Neutral', 'Happy'],
    datasets: [
      {
        label: 'Frequency',
        data: [11, 4, 1, 0, 0, 0, 0, 0],
        backgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
        hoverBackgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
      },
    ],
  };
  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const barData = {
    labels: ['Anger-Disgust', 'Anger-Sad', 'Anger-Contempt'],
    datasets: [
      {
        label: 'Frequency',
        data: [13, 2, 1],
        backgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
        hoverBackgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
      },
    ],
  };
  const moodBarData = {
    labels: ['Negative-Neutral', 'Negative-Positive'],
    datasets: [
      {
        label: 'Frequency',
        data: [13, 2],
        backgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
        hoverBackgroundColor: [
          '#ff6384', '#36a2eb', '#ffce56', '#66ff66', '#ff6666', '#ff9933', '#669999', '#ffccff'
        ],
      },
    ],
  };
  const barOptions = {
    elements: {
      bar: {
        barThickness: 15, 
        minBarLength: 10,
      },
    },  
    plugins: {
      legend: {
        display: false,
      },
    },

  };

  return (
    <div className="data-container">
      <header className="data-header">
        <Link to="/">
        <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
      </header>
      <div className="body">
        <div className="tabs">
          <button className={`tab-button ${activeTab === 'tab1' ? 'active' : ''}`} onClick={handleTab1}>Emotional Analysis</button>
          <button className={`tab-button ${activeTab === 'tab2' ? 'active' : ''}`} onClick={handleTab2}>Mood Analysis</button>
        </div>
        <div className="tab-content">
          {activeTab === 'tab1' && (
            <div className="content">
              <div className="emotional-analysis-container">
                <div>
                  <img src="/face-data-image.png" className="face-data-image"/>
                  <p><strong>Date:</strong> June 7, 2024</p>
                  <p><strong>Time:</strong> 11 : 50 AM - 12 : 30 PM</p>
                </div>
                <div className="emotional-data-middle">
                  <div className="emotional-frequency">
                    <p className="data-title">Top 3 emotion Frequency</p>
                    <table className="top-three-table">
                      <tr>
                        <td>Anger</td>
                        <td>11 <span style={{fontWeight:"normal"}}>(68.75%)</span></td>
                      </tr>
                      <tr>
                        <td>Disgust</td>
                        <td>4 <span style={{fontWeight:"normal"}}>(25%)</span></td>
                      </tr>
                      <tr>
                        <td>Sad</td>
                        <td>1 <span style={{fontWeight:"normal"}}>(6.25%)</span></td>
                      </tr>
                    </table>
                  </div>
                  <div className="emotional-pairs">
                    <p className="data-title">Emotion Pairs</p>
                    <div style={{width:"90%"}}>
                      <Bar data={barData} options={barOptions}/>
                    </div>
                  </div>
                  <div className="emotional-status-frequency">
                    <p className="data-title">Emotional Status Frequency</p>
                    <table className="strong-emotions-table">
                      <tr>
                        <td>Anger</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td>Disgust</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>Sad</td>
                        <td>1</td>
                      </tr>
                    </table>
                    <table className="other-emotions-table">
                      <tr>
                        <td>Contempt</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Fear</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Surprise</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Neutral</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Happy</td>
                        <td>0</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div>
                  <div className="overall-emotion-status ">
                    <p className="data-title">Overall Emotion Status</p>
                    <div className="pie-chart">
                      <Pie data={pieData} options={pieOptions}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'tab2' && (
            <div className="content">
              <div className="emotional-analysis-container">
                <div>
                  <img src="/face-data-image.png" className="face-data-image"/>
                  <p><strong>Date:</strong> June 7, 2024</p>
                  <p><strong>Time:</strong> 11 : 50 AM - 12 : 30 PM</p>
                </div>
                <div className="emotional-data-middle">
                  <div className="emotional-frequency">
                    <p className="data-title">Mood Frequency</p>
                    <table className="mood-frequency-table">
                      <tr style={{fontWeight:"bold"}}>
                        <td>Anger</td>
                        <td>16 <span style={{fontWeight:"normal"}}>(100%)</span></td>
                      </tr>
                    </table>
                  </div>
                  <div className="emotional-pairs">
                    <p className="data-title">Mood Pairs</p>
                    <div style={{width:"90%"}}>
                      <Bar data={moodBarData} options={barOptions}/>
                    </div>
                  </div>
                  <div className="emotional-status-frequency">
                    <p className="data-title">Mood Frequency</p>
                    <table className="strong-emotions-table">
                      <tr>
                        <td>Negative</td>
                        <td>11</td>
                      </tr>
                      <tr>
                        <td>Neutral</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>Positive</td>
                        <td>1</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Data;
