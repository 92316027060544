import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('googleUser'));
    if (user) {
      navigate('/home'); 
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Create a user object
    const user = { username, password };

    try {
      const response = await fetch('http://127.0.0.1:5000/user/user_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        console.log('User logged in', user);
        navigate('/');
      } else {
        const errorData = await response.json();
        console.error('Error logging in user:', errorData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const handleGoogleLoginSuccess = async (credentialResponse) => {
  //   try {
  //     const { credential } = credentialResponse;
  //     const userData = JSON.parse(atob(credential.split('.')[1]));
  //     localStorage.setItem('googleUser', JSON.stringify({
  //       firstName: userData.given_name,
  //       lastName: userData.family_name,
  //       email: userData.email,
  //       profilePicture: userData.picture
  //     }));
  
  //     // Check if third party user already exists
  //     const loginResponse = await fetch('http://127.0.0.1:5000/user/third_party_login', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(userData)
  //     });
  
  //     if (loginResponse.status === 404) {
  //       // User does not exist --> register
  //       const registerResponse = await fetch('http://127.0.0.1:5000/user/third_party_register', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(userData)
  //       });
  
  //       if (registerResponse.ok) {
  //         console.log('User registered successfully');
  //       } else {
  //         console.error('Registration failed');
  //         return;
  //       }
  //     } else if (loginResponse.ok) {
  //       const loginData = await loginResponse.json();
  //       console.log('Login successful:', loginData);
  
  //       // Store JWT
  //       localStorage.setItem('jwt', loginData.token);
  //       localStorage.setItem('isLoggedIn', 'true');
  //       navigate('/');
  //     } else {
  //       console.error('Login failed');
  //     }
  //   } catch (error) {
  //     console.error('Error during Google login:', error);
  //   }
  // };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const userData = JSON.parse(atob(credential.split('.')[1]));
      localStorage.setItem('googleUser', JSON.stringify({
        firstName: userData.given_name,
        lastName: userData.family_name,
        email: userData.email,
        profilePicture: userData.picture
      }));
  
      // Check if third party user already exists
      const loginResponse = await fetch('http://127.0.0.1:5000/user/third_party_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });
  
      if (loginResponse.status === 404) {
        // User does not exist --> register
        const registerResponse = await fetch('http://127.0.0.1:5000/user/third_party_register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });
  
        if (registerResponse.ok) {
          console.log('User registered successfully');
        } else {
          console.error('Registration failed');
          return;
        }
      } else if (loginResponse.ok) {
        // Parse the login response as text
        const loginText = await loginResponse.text();
  
        // Extract the token by removing "Bearer: " from the response
        const token = loginText.replace('Bearer: ', '').trim();
  
        // Store the token and login state
        localStorage.setItem('jwt', token);
        localStorage.setItem('isLoggedIn', 'true');
        console.log("successfully logged in")
        navigate('/');
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };
  

  const handleGoogleLoginError = () => {
    console.error('Login failed');
  };

  return (
    <div className="login-container">
      <div className="header">
        <Link to="/">
          <img src="/logo.png" className="app-logo" alt="Logo" />
        </Link>
      </div>
      <div className="Login">
        <div className="sign-in">
          <h2>Login</h2>
          <div className="link-party-container">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginError}
              style={{ margin: '10px 0' }}
            />
          </div>
          <p>or</p>
          <form onSubmit={handleLogin}>
            <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {!showPassword ? (
                <img src="/eye.png" className="eye" alt="Eye" onClick={togglePasswordVisibility} />
              ) : (
                <img src="/hidden-eye.png" className="eye" alt="Eye" onClick={togglePasswordVisibility} />
              )}
            </div>
            <button type="submit">Login</button>
            <div className="create-account">
              <p>Don't have an account?</p>
              <Link to="/register" style={{ textDecoration: 'underline' }}>
                <p style={{ fontWeight: 'bold'}}>Create account</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
